<div class="row justify-content-center align-items-end" *ngIf="response_success !== null && response_success" style="min-height: 50vh;">
    <div class="col-auto text-center">
            <img src="../../assets/images/payment_success.svg" />
            <h2 class="alert-heading text-success">Success!</h2>
            <p>Subscription has been completed!</p>
            <hr>
            <p class="mb-0">Please login with your email and use your<br/>
                <b class="text-dark">"surname" + "_2024"</b> as the default password!
            </p>
            <br/>
           

           
        
    </div>
   
</div>
<div class="row justify-content-center align-items-center" *ngIf="response_success" >
    <div class="col-auto text-center"> 
        <div class="text-start">
            <p>
                Example : <br/>
                Full Name : Juan dela Cruz<br/>
                Password : Cruz_2024
            </p>
        </div>    
        <div class="d-grid gap-2">
            <a href="login" class="btn btn-success">Login</a>
        </div>
    </div>
   
</div>

<div class="row justify-content-center align-items-center" *ngIf="response_success !== null && !response_success" style="min-height: 100vh;">
    <div class="col-auto">
        <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Failed!</h4>
            <p>Subscription failed!</p>
            <hr>
            {{this.response_error !== null? this.response_error["message"] : ""}}
        </div>
    </div>
</div>



